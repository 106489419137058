/**
 * Checkout Page has a Payment button, and a button on mobile screen which opens payment modal
 - default - By default the button on mobile is called 'Next' and the payment button
 * is 'Pay <amount>'
 - custom - In 'custom' button type we can update the text of these two buttons
 */
export const checkout_page_button_types = {
  default: 1,
  custom: 2,
};
