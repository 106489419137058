import { parseFileNameForUpload } from "../../../../../utils/common/file";
import { generateRandomString } from "../../String/utils/String";

/**
 * Generates a unique S3 image key for uploading images to an S3 bucket.
 *
 * The generated key includes a random string, a timestamp, and a
 * parsed version of the original file name, organized by user
 * and section for easy retrieval.
 *
 * @param {string} params.fileName - The original name of the file being uploaded.
 * @param {string} params.sectionName - The section under which the image is categorized.
 * @param {string} params.username - The username of the person uploading the image.
 *
 * @returns {string} - The generated S3 image key in the format:
 * "exly_images/{username}/{sectionName}/{randomString}_{timestamp}_{parsedFileName}".
 */
export const generateS3ImageKey = ({
  sectionName,
  username,
  fileName,
}: {
  fileName: string;
  sectionName: string;
  username: string;
}): string => {
  const s3ImageName =
    generateRandomString(3) +
    "_" +
    Date.now() +
    "_" +
    parseFileNameForUpload(fileName, { maxLength: 1000 }); // Adding maxLength to a bigger number to avoid length getting shorten
  return `exly_images/${username}/${sectionName}/${s3ImageName}`;
};
