const default_replace_all_special_chars_options = {
  char: "_",
  replaceConsecutive: true,
};

/**
 * replace all characters with a given character
 * @param str - string to be replaced
 * @param options.char - character to replace with. default: `"_"`
 * @param options.replaceConsecutive - default: `true`
 */
export const replaceAllSpecialChars: (
  str: string,
  options?: { char?: string; replaceConsecutive?: boolean },
) => string = (str, options) => {
  const char = options?.char || default_replace_all_special_chars_options.char;
  const returnVal = str.replace(/[^a-zA-Z0-9]/g, char);

  const replaceConsecutive =
    options?.replaceConsecutive ||
    default_replace_all_special_chars_options.replaceConsecutive;
  if (replaceConsecutive) {
    returnVal.replace(/_{2,}/g, char); // replace consecutive `_____` by just one `_`
  }

  return returnVal;
};

const VOWELS = new Set(["a", "e", "i", "o", "u"]);
/**
 * function takes a word and a count as parameters and returns the plural form of the word based on the count.
 * @param word  (string): The word that you want to pluralize.
 * @param count (number): The count associated with the word, which determines whether the plural form should be used.
 * @returns string representing the plural form of the input word based on the given count. If the count is 1, it returns the original word unchanged.
 */
export const pluralise = (word: string, count: number) => {
  if (!word) return "";

  const len = word.length;
  const hasYAtEnd = word[len - 1].toLowerCase() === "y";
  const hasVowelBeforeY = VOWELS.has(word[len - 2].toLowerCase());

  if (hasYAtEnd && !hasVowelBeforeY) {
    // If the word ends with 'y' and there is no vowel before 'y'
    const splittedWord = word.slice(0, -1);
    return count > 1 ? `${splittedWord}ies` : word;
  }

  // Default case for other words
  return count > 1 ? `${word}s` : word;
};

/**
 * referenced from https://stackoverflow.com/a/29858893
 * @returns capitalises each word in a string
 */
export const capitaliseWords = (str: string) => {
  const regex = /(\b[a-z](?!\s))/g;
  return str.replace(regex, (x) => {
    return x.toUpperCase();
  });
};

export const checkIsStringEmpty = (str: string | null | undefined = "") =>
  !str?.length;
