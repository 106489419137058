import { checkIsBrowser } from "./windowObj";

/**
 * @param url - url string to which params need to be added.\
 * Can be a full URL ("https://abcd.efg.hig/route?param1=value1") OR jus the route ("route?param1=value1" OR "/route?param1=value1")
 * @param params - Object containing key value pairs for params
 * @returns a new url with appended params
 */
export const appendURLSearchParams = (
  url: string,
  params: Record<
    symbol | number | string,
    number | string | Array<string> | boolean | undefined | null
  > = {},
) => {
  // prepare `urlBase` so that a proper URL object can be constructed from `url`
  let urlBase = "";
  if (!url.startsWith("https://") && !url.startsWith("http://")) {
    urlBase = "https://www.dummybaseurl.com";
  }

  try {
    const urlObj = new URL(urlBase + url);
    const urlSearchParams = new URLSearchParams(urlObj.search);
    Object.entries(params).forEach(([paramKey, paramValue]) => {
      // skip `undefined` and `null` parameters
      if ((paramValue ?? null) !== null) {
        urlSearchParams.append(paramKey, `${paramValue}`);
      }
    });

    // generate search string
    let finalSearchString = urlSearchParams.toString();
    if (finalSearchString) finalSearchString = "?" + finalSearchString;

    // get url without original search string and add `finaSearchString`
    return url.split("?")[0] + finalSearchString;
  } catch {
    // creating new URL might fail if the `url` structure is not proper
    return url;
  }
};

/**
 * Checks if the given URL is a Google Drive link.
 *
 * @param {string} url - The URL to be checked.
 * @returns {boolean} True if the URL is a Google Drive link, otherwise false.
 */
export const isGoogleDriveLink = (url: string) => {
  if (!url) return false;
  const driveLinkRegex = /^https:\/\/drive\.google\.com\/file\/d\/(.*?)\/.*?$/;

  return driveLinkRegex.test(url);
};

/**
 * Extracts the Google Drive file ID from a Google Drive URL.
 *
 * @param {string} url - The Google Drive URL.
 * @returns {string|null} The extracted Google Drive file ID or null if not found.
 */
export const getGoogleDriveFileIdFromUrl = (url: string) =>
  url ? url.match(/[-\w]{25,}/)?.[0] : null;

/**
 * Converts a Google Drive URL to a downloadable link.
 *
 * @param {string} url - The Google Drive URL.
 * @returns {string|undefined} The downloadable link or the original URL if conversion is not possible.
 */
export const getGoogleDriveDownloadableLink = (url: string) => {
  if (!url) return;

  const contentId = getGoogleDriveFileIdFromUrl(url);

  if (!contentId) return url;

  return appendURLSearchParams("https://drive.google.com/uc", {
    export: "download",
    id: contentId,
  });
};

/**
 * Processes a URL, converting Google Drive links to downloadable links if necessary.
 *
 * @param {string|undefined} url - The URL to be processed.
 * @returns {string|undefined} The processed URL, including a downloadable link if applicable.
 */
export const processVideoUrl = (url?: string) => {
  return url && isGoogleDriveLink(url)
    ? getGoogleDriveDownloadableLink(url)
    : url;
};

/**
 * Checks if the given link is a valid Loom video link.
 * Loom video links follow the pattern: https://www.loom.com/share/{videoId}?sid={sessionId}
 *
 * @param {string} link - The link to be checked.
 * @returns {boolean} - True if the link is a valid Loom video link, false otherwise.
 */
export const isLoomVideoUrl = (link) => {
  return /^https:\/\/www\.loom\.com\/share\/[a-zA-Z0-9]+\?sid=[a-fA-F0-9-]+$/.test(
    link,
  );
};

/**
 * Extracts the Loom video ID from a Loom video link.
 *
 * @param {string} loomLink - The Loom video link from which to extract the video ID.
 * @returns {string | null} - The extracted Loom video ID or null if not found.
 */
export const getLoomVideoId = (loomLink: string) => {
  const regex = /\/share\/([a-zA-Z0-9]+)\?sid=/;
  const match = loomLink.match(regex);
  return match ? match[1] : null;
};

/**
 * Generates the Loom video embed URL from a Loom video share URL.
 *
 * @param {string} loomUrl - The Loom video share URL.
 * @returns {string} - The corresponding Loom video embed URL.
 */
export const getLoomVideoEmbedUrlFromShareUrl = (loomUrl: string) => {
  if (!loomUrl?.length) return "";
  const loomVideoId = getLoomVideoId(loomUrl);
  return loomVideoId ? `https://www.loom.com/embed/${loomVideoId}` : "";
};

/**
 * Checks if the given URL is a Vimeo link.
 *
 * @param {string} url - The URL to be checked.
 * @returns {boolean} - Returns true if the URL is a Vimeo video link, otherwise false.
 * example urls:
 * 1. https://vimeo.com/809726316/93a3892635
 * 2. https://vimeo.com/809726316/
 * 3. https://vimeo.com/809726316
 * 4. https://www.vimeo.com/809726316/93a3892635
 * 5. https://www.vimeo.com/809726316/
 * 6. https://www.vimeo.com/809726316
 * 7. https://www.vimeo.com/staff/player
 */
export const isVimeoVideoLink = (url: string) => {
  if (!url?.length) return false;
  return /^(?:https?:\/\/)?(?:www\.)?vimeo\.com\/[a-zA-Z0-9]+(?:\/[a-zA-Z0-9]+)?\/?$/.test(
    url,
  );
};

/**
 * Retrieves all URL parameters with the 'utm_' prefix.
 * @returns An object containing all URL parameters with the 'utm_' prefix.
 */
export const getAllUtmParams = () => {
  const utmDetails: { [utmParamKey: string]: string } = {};

  if (checkIsBrowser()) {
    const urlParams: URLSearchParams = new URLSearchParams(
      window.location.search,
    );

    urlParams.forEach((value, key) => {
      if (key.startsWith("utm_")) {
        utmDetails[key] = value;
      }
    });
  }

  return utmDetails;
};
