import { replaceAllSpecialChars } from "./dataTypes/string";

export const getFileFormat = (fileName: string): string | undefined => {
  // needed to explicitly define return type: because typescript did not detect correct one automatically
  const indexOfLastDot = fileName.lastIndexOf(".");
  if (indexOfLastDot > -1) {
    return fileName.slice(indexOfLastDot + 1);
  }
};

export const getFileNameWOFormat = (fileName = "", fileFormat = "") => {
  const format = fileFormat || getFileFormat(fileName);
  if (format) {
    const dotFormat = `.${format}`;
    if (fileName.endsWith(dotFormat)) {
      return fileName.slice(0, -dotFormat.length);
    }
  }
  return fileName;
};

/**
 * replaces all special characters(consecutive ones too) in a filename(w/o file format) with just one `_`.
 * @param options.maxLength -
 * - max length of file name w/o file format
 * - send `0` to keep full name
 * - default: `10`
 */
export const parseFileNameForUpload: (
  fileName: string,
  options: { maxLength: number },
) => string = (fileName, options) => {
  const maxLength = (options || {}).maxLength ?? 10;
  const fileFormat = getFileFormat(fileName) || "";
  let fileNameWOFormat = replaceAllSpecialChars(getFileNameWOFormat(fileName));
  if (maxLength > 0) {
    fileNameWOFormat = fileNameWOFormat.slice(0, maxLength);
  }
  return `${fileNameWOFormat}.${fileFormat}`;
};
